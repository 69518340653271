import React, { Fragment } from 'react';
import { graphql } from 'gatsby';
import { AnchorLink } from 'gatsby-plugin-anchor-links';
import SEO from '../components/SEO';
import PreviewCompatibleImage from '../components/PreviewCompatibleImage';
import ShareButtons from '../components/ShareButtons';
import Header from '../components/Header';
import Footer from '../components/Footer';

export default function blogPostTemplate({ data, location }) {
  if (typeof data === `undefined`) {
    return;
  }

  const { markdownRemark } = data;
  const { fields, frontmatter, html, excerpt } = markdownRemark;
  const ogImagePath =
    frontmatter.featuredImage &&
    frontmatter.featuredImage.childImageSharp.fluid.src;

  const title = `Read ${frontmatter.title} `;
  const url = location.href;
  const twitterHandle = '_GUST';

  return (
    <Fragment>
      <SEO
        title={frontmatter.title}
        description={excerpt}
        image={ogImagePath}
        slug={fields.slug}
        isBlog={true}
      />
      <Header />
      <section id="hero___wrap" className="hero___wrap details">
        <div className="overlay"></div>
        <div className="container">
          <div className="slider-text">
            <div className="col-md-12">
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb d-flex justify-content-center">
                  <li className="breadcrumb-item">
                    <AnchorLink to="/#home" stripHash={true}>
                      Home
                    </AnchorLink>
                  </li>
                  <li className="breadcrumb-item">
                    <a href="/blog">Blog</a>
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">
                    {frontmatter.title}
                  </li>
                </ol>
              </nav>
            </div>
          </div>
        </div>
      </section>
      <div className="container mt-5 mb-5">
        <div className="blog-post">
          <h1 className="mt-3 mb-3 text-center font-weight-bold post-title">
            {frontmatter.title}
          </h1>
          <p className="text-center date">
            <i className="far fa-calendar-alt"></i>
            {frontmatter.date}
          </p>
          <div className="d-flex justify-content-center">
            <ShareButtons
              title={title}
              url={url}
              twitterHandle={twitterHandle}
            />
          </div>
          {frontmatter.featuredImage ? (
            <div className="featured-thumbnail img-fluid mt-5 mb-5">
              <PreviewCompatibleImage
                imageInfo={{
                  image: frontmatter.featuredImage,
                  alt: `featured image thumbnail for post ${frontmatter.title}`,
                }}
              />
            </div>
          ) : null}
          <div
            className="blog-post-content"
            dangerouslySetInnerHTML={{ __html: html }}
          />
        </div>
      </div>
      <Footer />
    </Fragment>
  );
}
export const pageQuery = graphql`
  query PostBySlug($id: String!) {
    markdownRemark(id: { eq: $id }) {
      id
      html
      excerpt(pruneLength: 150)
      fields {
        slug
      }
      frontmatter {
        date(formatString: "MMMM DD, YYYY")
        title
        featuredImage {
          childImageSharp {
            fluid(quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
`;
